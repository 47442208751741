.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 1em;
  padding: 1.2em 1.2em;
  justify-content: space-between;
  animation-name: enter;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  line-height: 1.5;

  strong {
    font-weight: 600;
  }
}

.warning {
  background-color: var(--alertOrangeBg);
}

.error {
  background-color: var(--blockingRedBg);
  color: var(--blockingRed);
}

.info {
  background: var(--brightBlue100);
  border: 1px solid var(--brightBlue500);
  color: var(--brightBlue500);
}

.success {
  background-color: var(--progressGreenBg);
  border: 1px solid var(--progressGreen);
  color: var(--progressGreen);
}

.error,
.info,
.success {
  & .message {
    color: var(--ink600);
  }
}

.icon {
  margin-right: 0.5em;
  font-size: var(--iconS) !important;
  color: inherit;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 !important;
  margin-left: 1em;
}

@keyframes enter {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (--max-width-sm) {
  .closeBtn {
    margin-left: 1em;
  }

  .message {
    text-align: left;
    margin: 0;
  }
}
